<template>
	<div class="partial partial--seed seed">
		<div class="seed__header" :style="{'background-image': 'url('+ hero +')'}">
			<div class="seed__header-content">
				<h1 class="seed__title" v-html="seed.title">{{ seed.title }}</h1>
			</div>
		</div>
		<div class="seed__subheader">
			<div class="seed__subheader-content content">
				<h2 class="seed__subtitle" v-html="seed.subtitle"></h2>
				<p class="seed__description" v-html="seed.description"></p>
			</div>
		</div>

		<div class="seed__list content">
			<div class="seed__list-item" v-for="(item, key) in seed.items" :key="key">
				<div class="seed__item-media">
					<img :src="getImg(`./${seed.key}_${item.type}.jpg`)" :alt="seed.label" />
				</div>
				<div class="seed__item-content">
					<h3 class="seed__item-label">
						<span>{{ item.label }}</span>
						<span class="seed__item-label-new" v-if="item.newYear">New for {{ item.newYear }}</span>
						<a class="seed__item-label-button" :href="`${publicPath}docs/${item.type}_tech_sheet.pdf`" target="_blank" v-if="!item.noTechSheet">View Tech Sheet</a>
					</h3>
					<h5 class="seed__item-features">
						<strong>Key Features:</strong>
						<span class="seed__item-feature" v-for="(feature, fkey) in parseFeatures(item.keyFeatures)" :key="`feature_${fkey}`">
							{{ feature }}
						</span>
					</h5>
					<p class="seed__item-description" v-html="item.description"></p>
					<div class="seed__item-meta">
						<template v-if="item.disease && parseDiseases(item.disease).length">
							<div class="seed__item-ratings">
								<h6>Disease Resistence</h6>
								<h6><small>(1 = Excellent to 9 = Weak)</small></h6>
								<dl class="seed__item-rating" v-for="(disease, dkey) in parseDiseases(item.disease)" :key="`disease_${dkey}`">
									<dt v-html="disease.label"></dt>
									<dd v-html="disease.value" :class="`rating-value rating-value--${disease.value}`"></dd>
								</dl>
							</div>
						</template>
						<template v-if="item.character && parseDiseases(item.character).length">
							<div class="seed__item-ratings">
								<h6>Agronomic Features</h6>
								<h6><small>&nbsp;</small></h6>
								<dl class="seed__item-rating" v-for="(character, ckey) in parseCharacters(item.character)" :key="`character_${ckey}`">
									<dt v-html="character.label"></dt>
									<dd v-html="character.value" :class="`rating-value rating-value--${character.value}`"></dd>
								</dl>
							</div>
						</template>
						<div class="seed__item-ratings">
							<h6>Growing Region</h6>
							<img :src="getRegion(item)" alt="Region" class="seed__item-region" />
						</div>
					</div>
				</div>
				<!-- {{ parseDiseases(item) }} -->
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'partialSeed',
	async asyncData({ store, route }) {
		let seeds = store.getters['seeds/seeds']
		if (!seeds || !seeds.length) {
			seeds = await store.dispatch('seeds/list')
		}

		let seed = seeds.find(s => s.key === route.params.seedId)
		if (seed) {
			await store.dispatch('seeds/get', seed.key)
		}
	},
	data: () => ({
		publicPath: process.env.BASE_URL,
	}),
	computed: {
		loading() {
			return this.$store.getters['misc/loading']
		},
		seed() {
			return this.$store.getters['seeds/seed']
		},
		hero() {
			let hero = ''
			try {
				hero = require(`@/assets/hero_${this.seed.key}.jpg`)
			} catch (error) {
				console.warn(error)
			}

			return hero
		},
	},
	destroyed() {
		this.$store.dispatch('seeds/unset')
	},
	methods: {
		parseDiseases(item) {
			let diseases = []
			if (!item) return diseases
			diseases = item.filter(n => n).map(i => {
				let [ label, value] = i.split(':')
				return { label, value }
			})

			return diseases
		},
		parseCharacters(item) {
			let characters = []
			if (!item) return characters
			characters = item.filter(n => n).map(i => {
				let [ label, value] = i.split(':')
				return { label, value }
			})

			return characters
		},
		parseFeatures(item) {
			let features = []
			if (!item) return features
			try {
				features = item.split(',').map(i => i.trim())
			} catch (error) {

			}

			return features
		},
		getRegion(item) {
			let region = null
			try {
				region = require(`@/assets/region_${item.region}.png`)
			} catch (error) {
				console.warn(error)
			}

			return region
		},
		getImg (path) {
			let _img = require.context('../../assets/', false, /\.(jpg|png)$/)
			let img = _img('./blank.jpg')
			try {
				img = _img(path)
			} catch (error) {
				console.warn(`Missing Image: ${path}`)
			}
			return img
		},
	},
	watch: {
		$route(to, from) {
			if (from.params.seedId && to.params.seedId && from.params.seedId != to.params.seedId) {
				let seeds = this.$store.getters['seeds/seeds']
				let seed = seeds.find(s => s.key === to.params.seedId)
				if (seed) {
					this.$store.dispatch('seeds/get', seed.key)
				}
			}
		},
	},
	metaInfo() {
		return {
			title: this.seed.title,
			meta: [
				{
					name: 'description',
					content: this.seed.description,
				}
			],
		}
	},
}
</script>

<style scoped lang="scss">
.seed {

	&__header {
		@include modules.ratio('custom', 238/1400);
		background-color: modules.color_('primary');
		background-position: center center;
		background-size: cover;
		border-bottom: 8px solid modules.color_('primary');

		@include modules.media-query('phone') {
			@include modules.ratio('custom', 438/1400);
		}

		&-content {
			display: flex;
			align-items: center;
			justify-content: center;
			text-align: center;
			color: modules.color_('primary', 'text');
		}
	}

	&__title {
		@include modules.fontSize(106px);
		font-weight: 900;
		text-transform: uppercase;
		text-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);

		@include modules.media-query('tablet') {
			@include modules.fontSize(76px);
		}

		@include modules.media-query('phone') {
			@include modules.fontSize(40px);
		}
	}

	&__subheader {
		&-content {
			color: modules.color_('secondary');
			text-align: center;
		}
	}

	&__subtitle {
		@include modules.fontSize(28px);
		font-weight: 700;
		text-transform: uppercase;
	}

	&__description {
		@include modules.fontSize(18px);
		font-weight: 500;
	}

	&__list {
		&-item {
			@include modules.gutter('padding-top');
			@include modules.gutter('margin-top');
			border-top: 2px solid modules.color_('secondary');
			display: flex;
		}
	}

	&__item {
		color: modules.color_('secondary');

		&-label {
			@include modules.fontSize(50px);
			color: modules.color_('secondary');
			display: flex;
			font-family: 'Open Sans', 'Raleway', sans-serif;

			align-items: center;
			font-weight: 800;
			text-transform: uppercase;
			margin: 0;
			margin-bottom: 0.5rem;
			padding: 0;

			@include modules.media-query('phone') {
				align-items: flex-start;
				flex-direction: column;
				justify-content: flex-start;
			}

			&-button {
				@include modules.fontSize(14px);
				@include modules.gutter('padding', 0.25);
				@include modules.gutter('padding-left', 1);
				background-color: modules.color_('primary');
				color: modules.color_('primary', 'text');
				display: flex;
				font-weight: 600;
				line-height: 1.2;
				margin-left: auto;
				position: relative;
				text-decoration: none;
				transition: background-color 150ms;

				&:before {
					content: '\A';
					border-style: solid;
					border-width: 5px 0 5px 10px;
					border-color: transparent transparent transparent #ffffff;
					display: block;
					position: absolute;
					left: 0.35rem;
					top: 0.5rem;
				}

				&:hover {
					background-color: modules.color_('secondary');
				}

				@include modules.media-query('phone') {
					margin-left: 0;
				}
			}

			&-new {
				@include modules.fontSize(14px);
				@include modules.gutter('padding', 0.25);
				@include modules.gutter('margin-left', 1);
				font-weight: 700;
				// @include modules.gutter('padding', 1);
				background-color: modules.color_('primary');
				color: modules.color_('primary', 'text');
				text-transform: uppercase;
			}
		}


		&-ratings {
			@include modules.fontSize(15px);
			text-align: center;

			h6 {
				@include modules.fontSize(17px);
				color: modules.color_('secondary');
				font-weight: 700;
				margin: 0;

				small {
					font-weight: 500;
				}
			}
		}

		&-rating {
			@include modules.fontSize(15px);
			align-items: center;
			color: modules.color_('secondary');
			display: flex;
			font-weight: 500;
			justify-content: space-between;
			text-align: left;
		}

		&-features {
			@include modules.fontSize(15px);
			color: modules.color_('secondary');
			display: flex;
			font-weight: 700;
			margin: 0;
			text-transform: uppercase;
			align-items: center;

			strong {
				white-space: nowrap;
			}

			@include modules.media-query('phone') {
				margin-bottom: 1rem;
				margin-top: 1rem;
				flex-direction: column;
			}
		}

		&-feature {
			border-left: 2px solid modules.color_('primary');
			display: block;
			margin-left: 1rem;
			padding-left: 1rem;
			text-transform: none;
		}

		&-description {
			color: modules.color_('secondary');
			font-weight: 500;
		}

		&-meta {
			@include modules.gutter('column-gap', 3);
			display: grid;
			grid-template-columns: 1fr 1fr 1fr;

			@include modules.media-query('tablet') {
				grid-template-columns: 1fr 1fr;
				// display: flex;
				// flex-direction: column;
			}

			@include modules.media-query('phone') {
				display: flex;
				flex-direction: column;
			}
		}

		&-region {
			max-width: 100%;
		}

		&-content {
			flex: 0 1 100%;
		}

		&-media {
			flex: 0 1 280px;
			padding-top: 0.75rem;
			padding-right: 1rem;

			img {
				max-width: 100%;
			}

			@include modules.media-query('tablet') {
				flex: 0 1 25%;
			}

			@include modules.media-query('phone') {
				flex: 0 1 35%;
			}
		}
	}

	&__description {
		white-space: pre-line;
	}

	.rating {
		&-value {
			font-weight: 700;
		}

		&-value {
			&--1,
			&--2,
			&--3,
			&--4,
			&--5,
			&--6,
			&--7,
			&--8,
			&--9 {
				@include modules.fontSize(20px);
				background-color: modules.color_('secondary');
				color: white;
				display: inline-block;
				font-weight: bold;
				flex: 0 0 2rem;
				text-align: center;
				margin-left: 0.5rem;
				height: 1.6rem;
				width: 2rem;
			}

			&--1 {
				background-color: #70d82a;
			}
			&--2 {
				background-color: #9ecd25;
			}
			&--3 {
				background-color: #b5cd15;
			}
			&--4 {
				background-color: #c9cc07;
			}
			&--5 {
				background-color: #d4bc00;
			}
			&--6 {
				background-color: #d49b00;
			}
			&--7 {
				background-color: #d46e00;
			}
			&--8 {
				background-color: #d43f00;
			}
			&--9 {
				background-color: #d41700;
			}
		}
	}
}
</style>
